<template>
  <div class="select-checked">
    <el-select
      :value="selected"
      :class="{ all: optionsAll }"
      multiple
      :disabled="disabled"
      :placeholder="$strings.please_choose_input"
      :popper-append-to-body="false"
    >
      <el-option :value="'all'" :label="$strings.all" class="multiple">
        <el-checkbox v-model="optionsAll" @change="handleoptionsAllChange">
         {{ $strings.all}}
        </el-checkbox>
      </el-option>
      <el-option
        class="multiple"
        :value="item.value"
        :label="item.label"
        v-for="(item, key) in optionsData"
        :key="key"
      >
        <el-checkbox v-model="item.check" @change="handleTaskItemChange(item)">
          {{ item.label }}
        </el-checkbox>
      </el-option>
    </el-select>
  </div>
</template>
 
<script>
export default {
  name: 'SelectAll',
  components: {},
  props: {
    options: {
      type: Array,
      default:()=>[]
    },
    disabled: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      optionsData: [],
      optionsAll: false,
      selectedOptions: []
    }
  },
  watch: {
    options: {
      handler(newVal) {
        this.optionsData = JSON.parse(JSON.stringify(newVal)) 
      },
      immediate: true,
      deep: true, // 深度监听
    }
  },
  beforeDestroy() {
    
    this.optionsData.forEach(item => {
      delete item.check
    })
    this.selectedOptions = []
  },
  computed: {
    selected() {
      const currnetArr = JSON.parse(JSON.stringify(this.selectedOptions))
      if (currnetArr.length >= this.optionsData.length) {
        return ['all']
      } else {
        return currnetArr
      }
    }
  },
  methods: {
    handleoptionsAllChange(isAll) {
      if (isAll) {
        this.optionsData.forEach((elm, idx) => {
          elm.check = true
          this.selectedOptions.push(elm.value)
        })
      } else {
        this.optionsData.forEach((elm, idx) => {
          elm.check = false
        })
        this.selectedOptions = []
      }
      this.$emit('selected',this.selectedOptions)
    },
    handleTaskItemChange(item) {
      if (!item.check) {
        let index = this.selectedOptions.findIndex((ele) => item.value === ele.value)
        this.selectedOptions.splice(index, 1)
      } else {
        this.selectedOptions.push(item.value)
      }
     
      this.optionsAll = this.selectedOptions.length === this.optionsData.length
      // console.log(this.selectedOptions, this.optionsData)
      this.$emit('selected', this.selectedOptions)
    },
    setselectedOptions(str, val) {
      const arrList = JSON.parse(JSON.stringify(val))
      const currentKeys = str.split(',')
      this.selectedOptions = []
      this.optionsAll =false
      currentKeys.forEach((item, index) => {
        this.$set(this.selectedOptions,index,item*1)
      })
      this.optionsData=arrList.map(item => {
            if (this.selectedOptions.includes(item.value)) {
              item.check = true
            } else {
              item.check = false
            }
            return item
        })
      if (this.optionsData.length <= currentKeys.length) {
        this.optionsAll =true
      }
      
    }
  }
}
</script>
 
<style lang="scss">
.select-checked {
  .el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
    content: '';
  }
  .el-checkbox {
    width: 100%;
    padding: 0 30px;
    .el-checkbox__label {
      margin-left: 20px;
    }
  }
  .el-select-dropdown__item {
    padding: 0;
  }
  .el-tag__close,
  .el-icon-close {
    display: none;
  }
  .el-tag.el-tag--info {
    background: transparent;
    border: 0;
  }
 
  .el-select {
    .el-select__tags {
      flex-wrap: nowrap;
      overflow: hidden;
    }
    .el-tag {
      background-color: #fff;
      border: none;
      color: #606266;
      font-size: 13px;
      padding-right: 0;
      & ~ .el-tag {
        margin-left: 0;
      }
      &:not(:last-child)::after {
        content: ',';
      }
    }
  }
}
</style>