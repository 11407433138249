<script>
export default {
  name: "table-setting",
  data() {
    return {
      isIndeterminate: true,
      checkAll: false,
      checkedColumns: [],
      excludeHideColumns: [], // 排除需要隐藏的列
    };
  },
  props: {
    columnOptions: {
      type: Array,
      default: () => [],
    },
    hideColumns: {
      // 需要隐藏的列，label名称
      type: Array,
      default: () => [],
    },
    hiddenArr: {
      // 需要隐藏的列，label名称
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.initCheckedColumns();
  },
  watch: {
    hideColumns() {
      this.excludeHideColumns = this.columnOptions.filter(
        (column) => !this.hideColumns.includes(column.label)
      );
    },
    
  },
  methods: {
    initCheckedColumns() {
      
      this.excludeHideColumns = this.columnOptions.filter(
        (column) => !this.hideColumns.includes(column.label)&&column.label
      );
      
      if (this.hiddenArr) {
        this.checkedColumns = this.excludeHideColumns.map((item) => { if (!item.hide&&!this.hiddenArr.includes(item.prop)) { return item.label } }).filter(item=>item);
      } else {
        this.checkedColumns = this.excludeHideColumns.map((item) => { if (!item.hide) { return item.label } }).filter(item=>item);
      }
      this.checkAll =this.hiddenArr?this.checkedColumns.length === this.excludeHideColumns.length : true;
      this.isIndeterminate = this.checkedColumns.length !== this.excludeHideColumns.length;
      if (!this.checkedColumns.length) {
        this.isIndeterminate = false;
      }
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      if (val) {
        this.checkedColumns = this.columnOptions.map((item) => item.label);
      } else {
        this.checkedColumns = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedColumnChange(value) {
      const excludeHideColumns = this.columnOptions.filter(
        (column) => !this.hideColumns.includes(column.label)&&column.label
      );
      let checkedCount = value.length;
      this.checkAll = checkedCount === excludeHideColumns.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < excludeHideColumns.length;
    },
    handleConfirm() {
      this.$emit("confirm", this.checkedColumns);
    },
  },
  render() {
    return (
      <el-dialog
        title={this.$strings.table_setting}
        {...{ attrs: this.$attrs }}
        {...{ on: this.$listeners }}
      >
        <div class="table-setting-item">
          <p class="table-setting-item-title">{this.$strings.table_filed_setting}</p>
          <div class="table-setting-item-content">
            <el-checkbox
              indeterminate={this.isIndeterminate}
              vModel={this.checkAll}
              on-change={this.handleCheckAllChange}
              style="margin-bottom:10px;"
            >
              {this.$strings.all_choose}
            </el-checkbox>

            <el-checkbox-group
              vModel={this.checkedColumns}
              on-change={this.handleCheckedColumnChange}
            >
              {this.excludeHideColumns.map((item) => {
                return item.label ? (
                  <el-checkbox
                    label={item.label}
                    key={item.label}
                    style="margin-bottom:10px;"
                  >
                    {item.label}
                  </el-checkbox>
                ) : null;
              })}
            </el-checkbox-group>
          </div>
        </div>
        <div slot="footer">
          <el-button on-click={this.handleConfirm} type="primary">
            {this.$strings.submit}
          </el-button>
        </div>
      </el-dialog>
    );
  },
};
</script>

<style lang="scss">

</style>
