const number = {
  bind(el, binding, vnode, oldVnode) {
    var input = el
    input.oninput = function(e) {
      if (e.target.value.length === 1) {
        e.target.value = e.target.value.replace(/[^0-9]/g, '')
      } else {
        e.target.value = e.target.value.replace(/[^\d]/g, "")
      }
    }
    input.onblur = function(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "")
    }

  },
  update(el, binding, vnode, oldVnode) {

  }
}
export default number
