<template>
  <div >
      <el-upload
      v-if="needElement"
        ref="upload"
        :accept="accept"
        :disabled="disabled"
        :fileList="fileList"
        :show-file-list="false"
        :http-request="handleFiles"
        :listType="'object'"
        action
        drag
      >
      <div class="add_file_icon"><icon type="xinzeng" other-class="m_b_20" /></div>
        
        <div class="el-upload__text">
          {{$strings.upload_text_drag}}
          <div class="limit_text" v-if="limitTextFlag">{{ limitText||$insertMsg($strings.upload_size_insert,'854*480','png/jpg') }} </div>
        </div>
      </el-upload>
      <input v-if="!needElement" :disabled="disabled" :id="`uploader${new Date()*1}`" ref="input" v-show="false" :accept="accept" type="file" @change="handleFiles" >
  </div>
  
</template>

<script>
import {addFile,delFile} from '@/api/admin/upload'
import SparkMD5 from "spark-md5";
import icon from '@/components/globalComponents/icon'
export default {
  name: 'ExcelUpload',
  components: {
    icon
  },
  props: {
    bucket: {
      type: String,
      default:''
    },
    accept: {
      type: String,
      default:".png, .jpg"
    },
    needElement: {
      type: Boolean,
      default:true
    },
    limit: {
      type: Number,
      default:1
    },
    getFileList: {
      type: Function,
      default:()=>{}
    },
    compatible: {
      type: Boolean,
      default:false
    },
    limitTextFlag: {
      type: Boolean,
      default:true
    },
    limitText: {
      type: String,
      default:''
    },
    size: {
      type: Number,
      default:5
    },
    errback: {
      type: Function,
      default:()=>{}
    },
    disabled: {
      type: Boolean,
      default:false
    }
  },
  data() {
    return {
      upload: {
        open: false,
        isUploading: false
      },
      fileList: [],
      compatibleList:[
        ".jpeg", ".jpg", ".png", ".gif", ".bmp", 
        ".tiff", ".tif", ".webp", ".svg", ".ico",
        ".heif", ".heic", ".raw", ".cr2", ".nef", 
        ".arw", ".orf", ".rw2", ".xgq", ".dds", 
        ".exr", ".icns", ".jpf", ".jpx", ".pct", 
        ".pgf", ".psd", ".tga",".tts"
    ]
    }
  },
  computed: {
    
  },
  methods: {
    getFile() {
      this.$refs.input.click()
    },
    handleFiles(data) {//文件上传
      const spark = new SparkMD5.ArrayBuffer()
      let file = this.needElement ? data.file : data.target.files[0]
      const fileName = file.name;
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      const ext = `.${fileExtension}`
      const otherExt = file.type.split('/')[1]
      if(this.accept.indexOf(ext) == -1&&!this.compatible){
        this.$message.error(this.$strings.data_update_error)
        return
      }
      if(this.compatible && this.compatibleList.indexOf(ext) == -1&&this.compatibleList.indexOf(`.${otherExt}`) == -1){
        this.$message.error(this.$strings.data_update_error)
        return
      }
      if (file.size > this.size * 1024*1024) {
        this.$message.error(this.$insertMsg(this.$strings.img_size_other,`${this.size}M`))
        return
      }
      const reader = new FileReader()
      reader.readAsArrayBuffer(file)
      reader.onload = (e) => {
        spark.append(e.target.result);
        const md5 = spark.end();
        const res = e.target.result//ArrayBuffer
        const key = this.getFileKey(file.name, md5)
        
        
          let params = new FormData();
          params.append("file", file);
          params.append("directory", this.bucket);
        // params.append("fileName", key);
        addFile(params).then(res => {
          if (res.data.code !== 0) {
            this.errback()
            return
          }
          let data = res.data.data
          if (this.needElement) {
            this.fileList.push({
              url:data.fullUrl,
              key: key,
              name:file.name
            })
            this.getFileList(this.fileList)
          } else {
            this.$emit('getUrl', {
              url: data.fullUrl,
              key:key
            })
            }
           
        }).catch(error => {
          this.errback()
          })
        

      }
    },
    getFileUrl(file, key, md5) {
      minioClient.presignedGetObject('angelwatch', key, 24 * 60 * 60, (err, presignedUrl) => {
        if (err) {
        } else {
          this.fileList.push({
            url: presignedUrl.split('?')[0],
            key,
            name:file.name
          })
          this.getFileList(this.fileList)
        }
      })
    },
   
    getFileKey(name, md5) {
      if (!name) return
      const nameArr = name.split('.')
      const accept = nameArr[nameArr.length - 1]
      return `${md5}.${accept}`
    },
    delete(name) {
      delFile({
        ossUrl:name
      }).then(res => {
        this.fileList = this.fileList.filter(item => {
            if (item.url != name) {
              return item
            }
        })
          this.getFileList(this.fileList)
      }).catch(error=>{})
    }
  }
}
</script>
<style lang="scss" >
@import '@/styles/theme.scss';
.add_file_icon{
  margin-top: 44px;
  margin-bottom: 22px;
}
.el-upload-dragger{
  @extend .bac_1;
  width: 400px;
  height: 160px;
}
.limit_text{
  font-size: 12px;
  font-weight: 400;
  @extend .font_3;
  line-height: 20px;
  margin: 4px 16px 0 16px;
}
.el-upload-list__item{
  padding: 12px 5px;
  .el-icon-close {
    top: 18px;
    right: 12px;
  }
}
</style>