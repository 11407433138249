
  
<script>
let el;
import { mapGetters } from "vuex";
import TableSetting from "./table-setting.vue";
import copy from '@/util/clipboard'
export default {
  name: 'tmsCardList',
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    hideColumns: {
      // 需要隐藏的列，label名称
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    columnsKeyName: null,
    align: {
      type: String,
      default: "left",
    },
    alignKey: {
      type: String,
      default: "vin",
    },
    itemName: {
      type: String,
      default: "",
    },
    imgKey: {
      type: String,
      default: "",
    },
    showPagination: {
      // 是否展示分页控件
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 50, 100, 200],
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    layout: {
      type: String,
      default: "total, prev, pager, next,sizes",
    },
    total: {
      type: Number,
      default: 0,
    },
    selectList:{
      type: Array,
      default: () => [],
    },
    paginationStyle: {
      type: Object,
      default() {
        return {
          "margin-top": "16px",
        };
      },
    },
    storeName: {
      type: String,
      default:''
    },
    selectable: {
      // 单选按钮是否可勾选
      type: Boolean,
      default: () => false,
    },
    showOverflowTooltip: {
      // 全局行配置，内容过多是否展示省略
      type: Boolean,
      default: true,
    },
    showTableSetting: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: Array,
      default: () => ["tms-table-wrapper"],
    },
    currentChange: {
      type: Function,
      default: () => {},
    },
    cardWidth: {
      type: Number,
      default:302
    },
    outWidth: {
      type: Number,
      default:312
    },
    outHeight: {
      type: Number,
      default:386
    }
  },
  components: {  TableSetting },
  data() {
    return {
      page: {
        // 当前页改变和页面size改变
        pageSize: 10,
        currentPage: 1,
      },
      heightNum: this.innerHeight,
      itemWidth: '',
      selectedShowColumns:[],
      tableSettingDialogVisible: false,
      selectableOriData:[]
    }
  },
  // provide() {
  //   return {
  //     tableRoot: this,
  //     heightNum:window.innerHeight
  //   };
  // },
  computed: {
    ...mapGetters([
      'innerHeight',
      'innerWidth',
      'subWidth',
      'userInfo',
      'tableSettingMap'
    ])
  },
  watch: {
    innerHeight() {
        this.heightNum = this.innerHeight - this.outHeight
    },
    columns: {
      handler: (val) => {
        this.initSelectedShowColumns();
      },
      deep:true
    },
    tableData: {
      handler: function(val) {
        this.selectableOriData = val
      },
      deep:true
    },
    currentPage(val) {
      // 当前currentPage 需是data属性
      this.page.currentPage = val;
    },
    subWidth(e) {
      this.getWidth(this.innerWidth)
    },
    innerWidth(e) {
      this.getWidth(e)
    }
  },
  created() {
    el = this
    this.getWidth(innerWidth)
    this.initSelectedShowColumns()
  },
  methods: {
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.$emit("size-change", val);
      // 当前页改变和页面size改变
      this.$emit("page-change", this.page);
    },
    //选择操作数据改动
    getSelectIds(row) {
      this.selectableOriData = this.selectableOriData.map(item => {
        if (item.id === row.id&&!row.selectable ) {
          item.selectable = true
        } else if(item.id === row.id&&row.selectable){
          item.selectable = false
        }
        return item
      })
      const adds = this.selectableOriData.filter(item => item.selectable)
      this.$emit('selection-change',adds)
    },
    handleCurrentChange(val) {
      this.currentChange(val)
      this.page.currentPage = val;
      // 当前页改变和页面size改变
      this.$emit("page-change", this.page);
    },
    openColumnSettingDialog() {
      this.tableSettingDialogVisible = true;
    },
    initSelectedShowColumns() {
      const hiddenArr = this.tableSettingMap[this.storeName]
      this.selectedShowColumns = this.columns.filter(
        (column) => hiddenArr?!this.hideColumns.includes(column.label)&&!hiddenArr.includes(column.prop):!this.hideColumns.includes(column.label)
        
      );
    },
    getWidth(e) {
      const wrapper_width = (e - this.outWidth - this.subWidth)
      const current_lengen = Math.floor(wrapper_width / this.cardWidth)
      this.itemWidth = ((wrapper_width / current_lengen) - 25) 
    },
    handleCopy(text, event) {
      copy(text, event,this.$strings)
    },
    confirmTableSetting(checkedColumns) {
      const hiddenArr = []
      this.columns.forEach((item) => {
        if (checkedColumns.includes(item.label)) {
          item.hide = false;
        } else {
          if (item.label) {
            item.hide = true;
            
          }
          hiddenArr.push(item.prop)
        }
      });
      this.$store.commit('SET_TABLESETTINGMAP', {
        name: this.storeName,
        content:hiddenArr
      })
      this.tableSettingDialogVisible = false;
    },
  },
  render() {
    const that = this;
    this.selectableOriData = this.tableData
    const fullFLag = that.tableData.length <= 8 && that.tableData.length > 4 && that.itemName 
    const arr = []
    if (fullFLag) {
      const fullNumber = 10-that.tableData.length
      for (let index = 0; index < fullNumber; index++) {
        arr.push(1)
      }
      //避免滾動條隱藏 有bug
      // let current_wrap = document.getElementsByClassName('care_list_wrapper')[0]
      // current_wrap.style.overflow = 'hidden'
    }
    
    
    return (
      <div >
        <div class="care_list_wrapper" style={{'height':that.innerHeight-that.outHeight + 'px','min-width':'1150px'}}>
          {that.tableData.length?that.tableData.map((row) => {
            return <div class={`card_item ${that.itemName}`} style={{'width':that.itemWidth+'px'}}>
              <div class="card_item_header" onclick={(e) => {
                e.stopPropagation()
                if (!that.selectable) {
                           that.$emit('gotoDetail',row)
                        }
                        }}>
              {that.selectable ? <span class='m_r_12' > <el-checkbox value={that.selectList.includes(row.id)} onchange={() => {
                    that.getSelectIds(row)
                  }} /> </span>: null}
                <div class={that.selectable?"vin flex_1":"vin just_between"} >
                  <span>{row[that.imgKey] ? <img class="img_24 m_t_11 m_r_12" src={row[that.imgKey]} /> : null}</span>
                  <el-tooltip content={row[that.alignKey]} class='vin_value_header' placement="bottom-start" visible-arrow={false}	>
                  <div onclick={(e) => {
                    e.stopPropagation()
                        that.$emit('gotoDetail',row)
                      
                  }}>
                    
                      {row[that.alignKey]}
                    
                    
                 
                  </div></el-tooltip>
                </div >
                <div>
                  <i on-click={(e) => {
                    e.stopPropagation()
                    that.handleCopy(row[that.alignKey], e)
                  }} class='iconfont icon-fuzhi copy_table font_primary font_s_12' />
                      <tms-icon type="you" />
                </div>
                    </div>
            
                    <div class="card_item_content">
                {that.columns.map((column, $i) => {
                  const hiddenArr = that.tableSettingMap[that.storeName]
                  const showFlag = hiddenArr?!hiddenArr.includes(column.prop):true
                          if (column.prop !== 'operate'&&!column.hide&&!column.hiddenCard&&showFlag) {
                            return <div class="card_content_it">
                                    {<div class="vin_label" >{column.label}</div>}
                                    {column.render&&column.label!='IMEI-IMSI-icc_id' ?
                                  <el-tooltip  placement="bottom-start" visible-arrow={false}  content={column.render(row, column, $i)?column.render(row, column, $i):''}>
                                  <div class="vin_value render_tms" >{column.render(row, column, $i)} {row[column.prop]&&column.prop!=='fileSize' ? <i on-click={(e) => { that.handleCopy(row[column.prop], e) }} class='iconfont icon-fuzhi copy_table' /> : null}</div>
                                  </el-tooltip>:
                                <div class="vin_value render_tms" >
                                  <el-tooltip   content={row[column.prop]?row[column.prop]+'':''} visible-arrow={false} placement="bottom-start">
                                    <span class=''>{row[column.prop]}{row[column.prop]&&column.prop!=='fileSize' ? <i on-click={(e) => { that.handleCopy(row[column.prop], e) }} class='iconfont icon-fuzhi copy_table' /> : null}</span>
                                  </el-tooltip>
                                  
                                </div>
                                      } 
                      
                                  </div>
                          }
                          })}
                    </div>
              { that.columns.some(item=>item.prop==='operate')&&(!that.tableSettingMap[that.storeName]||!that.tableSettingMap[that.storeName].includes('operate'))?<div class="card_item_footer"  >
                        {that.columns.map((column,$i) => {
                          return column.prop==='operate'&&  column.render(row, column, $i)
                        })}
                    </div>:null}
                  </div>
          }): (<div class='tac font_s_12' style={{'line-height':that.innerHeight-that.outHeight + 'px','width':'100%'}}>
              {that.$strings.no_data}
          </div>)}
          {that.tableData.length <= 8 && that.tableData.length > 4 && that.itemName ? arr.map(item => {
              return<div class={` ${that.itemName}`} style={{'width':that.itemWidth+'px',height:'200px'}}></div>
            }):null}
        </div>
        {that.showPagination && (
          // 分页组件
          <div class="tms-table-pagination" style={that.paginationStyle}>
            <el-pagination
              vOn:size-change={that.handleSizeChange}
              vOn:current-change={that.handleCurrentChange}
              {...{
                on: {
                  "update:currentPage": (val) => {
                    that.page.currentPage = val
                  },
                },
              }}
              pageSizes={that.pageSizes}
              pageSize={that.pageSize}
              layout={that.layout}
              current-page={that.currentPage}
              total={that.total}
            ></el-pagination>
          </div>
        )}
        <table-setting
          visible={that.tableSettingDialogVisible}
          columnOptions={that.columns}
          hideColumns={that.hideColumns}
          hiddenArr = {that.tableSettingMap[that.storeName]}
          {...{
            on: {
              "update:visible": (val) => (that.tableSettingDialogVisible = val),
              confirm: that.confirmTableSetting,
            },
          }}
        />
      </div>
    );
  }
}
</script>
  <style lang="scss" >
  @import '@/styles/theme.scss';
  @import '@/styles/layout.scss';
  .care_list_wrapper{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    padding: 5px 5px 0 5px;
    .card_item{
      margin-right: 16px;
      display: flex;
      max-height: 420px;
      flex-direction: column;
      margin: 0 16px 16px 0;
      border: 1px solid ;
      @extend .border_3;
      border-radius: 4px;
      .card_item_header{
        height: 46px;
        line-height: 46px;
        border-bottom: 1px solid;
        font-size: 16px;
        font-weight: 500;
        @extend .border_3;
        padding: 0 16px 0 16px;
        display: flex;
        justify-content: space-between;
        @extend .font_1;
        cursor: pointer;
        .vin{
          max-width: 270px;
        }
        .vin_value_header{
          @extend .font_1;
          @extend .ellipsis;
          width: 200px;
        }
      }
      .card_item_content{
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 16px 16px 0 16px;
        font-size: 16px;
        
        .card_content_it{
          height: 22px;
          font-size: 14px;
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;
          .vin_label{
            @extend .font_3;
            @extend .ellipsis;
            width: 150px;
          }
          .vin_value{
            @extend .font_1;
            @extend .ellipsis;
            width: 170px;
            text-align: right;
          }
        }
      }
      .card_item_footer{
        padding: 0 16px 16px 16px;
        height: 52px;
        line-height: 36px;
        @extend .font_1;
        display: flex;
        justify-content: flex-end;
      }
    }
    .card_item:hover{
      box-shadow: 0px 0px 5px 5px #e8efff;
      .card_item_header{

      }

      cursor: pointer;

    }
  } 
  .care_list_wrapper::after{
      content: "";
      display: block;
      flex-grow: 99999;
    }
    
@keyframes shake_card {
	2% {
		transform: rotate(-0.5deg);
	}
	4% {
		transform: rotate(-0.5deg);
	}
	6% {
		transform: translate(-6px, 9px) rotate(2.5deg);
	}
	8% {
		transform: translate(-8px, 9px) rotate(0.5deg);
	}
	10% {
		transform: translate(-6px, -1px) rotate(0.5deg);
	}
	12% {
		transform: translate(-9px, 2px) rotate(2.5deg);
	}
	14% {
		transform: translate(-2px, 9px) rotate(-1.5deg);
	}
	16% {
		transform: translate(0px, 10px) rotate(-0.5deg);
	}
	18% {
		transform: translate(-3px, -3px) rotate(3.5deg);
	}
	20% {
		transform: translate(8px, -1px) rotate(3.5deg);
	}
	22% {
		transform: translate(10px, -4px) rotate(-0.5deg);
	}
	24% {
		transform: translate(0px, -8px) rotate(0.5deg);
	}
	26% {
		transform: translate(-1px, 2px) rotate(-1.5deg);
	}
	28% {
		transform: translate(8px, 8px) rotate(-1.5deg);
	}
	30% {
		transform: translate(-9px, 5px) rotate(-0.5deg);
	}
	32% {
		transform: translate(1px, 10px) rotate(1.5deg);
	}
	34% {
		transform: translate(7px, -4px) rotate(3.5deg);
	}
	36% {
		transform: translate(2px, -8px) rotate(-1.5deg);
	}
	38% {
		transform: translate(6px, 10px) rotate(-2.5deg);
	}
	40% {
		transform: translate(3px, -1px) rotate(0.5deg);
	}
	42% {
		transform: translate(-5px, -4px) rotate(-0.5deg);
	}
	44% {
		transform: translate(-3px, 10px) rotate(-2.5deg);
	}
	46% {
		transform: translate(-7px, 2px) rotate(-2.5deg);
	}
	48% {
		transform: translate(-5px, -1px) rotate(3.5deg);
	}
	50% {
		transform: translate(-7px, -1px) rotate(1.5deg);
	}
	52% {
		transform: translate(2px, 8px) rotate(-1.5deg);
	}
	54% {
		transform: translate(7px, -9px) rotate(0.5deg);
	}
	56% {
		transform: translate(-4px, 1px) rotate(1.5deg);
	}
	58% {
		transform: translate(-2px, -8px) rotate(1.5deg);
	}
	60% {
		transform: translate(-7px, 1px) rotate(-0.5deg);
	}
	62% {
		transform: translate(-5px, -2px) rotate(-0.5deg);
	}
	64% {
		transform: translate(-2px, 5px) rotate(-2.5deg);
	}
	66% {
		transform: translate(-2px, 7px) rotate(3.5deg);
	}
	68% {
		transform: translate(-7px, -1px) rotate(-0.5deg);
	}
	70% {
		transform: translate(-5px, 8px) rotate(-2.5deg);
	}
	72% {
		transform: translate(-3px, -9px) rotate(-2.5deg);
	}
	74% {
		transform: translate(-2px, -7px) rotate(3.5deg);
	}
	76% {
		transform: translate(-5px, -4px) rotate(2.5deg);
	}
	78% {
		transform: translate(-2px, 10px) rotate(-1.5deg);
	}
	80% {
		transform: translate(4px, 9px) rotate(3.5deg);
	}
	82% {
		transform: translate(3px, -1px) rotate(-1.5deg);
	}
	84% {
		transform: translate(4px, -6px) rotate(0.5deg);
	}
	86% {
		transform: translate(-1px, 4px) rotate(-0.5deg);
	}
	88% {
		transform: translate(10px, -5px) rotate(3.5deg);
	}
	90% {
		transform: translate(-3px, 7px) rotate(-0.5deg);
	}
	92% {
		transform: translate(5px, -2px) rotate(2.5deg);
	}
	94% {
		transform: translate(-2px, -7px) rotate(-0.5deg);
	}
	96% {
		transform: translate(0px, 10px) rotate(-2.5deg);
	}
	98% {
		transform: translate(-4px, 3px) rotate(2.5deg);
	}
	0%,
	100% {
		transform: translate(0, 0) rotate(0);
	}
}

  </style>
