var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "select-checked" },
    [
      _c(
        "el-select",
        {
          class: { all: _vm.optionsAll },
          attrs: {
            value: _vm.selected,
            multiple: "",
            disabled: _vm.disabled,
            placeholder: _vm.$strings.please_choose_input,
            "popper-append-to-body": false,
          },
        },
        [
          _c(
            "el-option",
            {
              staticClass: "multiple",
              attrs: { value: "all", label: _vm.$strings.all },
            },
            [
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleoptionsAllChange },
                  model: {
                    value: _vm.optionsAll,
                    callback: function ($$v) {
                      _vm.optionsAll = $$v
                    },
                    expression: "optionsAll",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$strings.all) + " ")]
              ),
            ],
            1
          ),
          _vm._l(_vm.optionsData, function (item, key) {
            return _c(
              "el-option",
              {
                key: key,
                staticClass: "multiple",
                attrs: { value: item.value, label: item.label },
              },
              [
                _c(
                  "el-checkbox",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.handleTaskItemChange(item)
                      },
                    },
                    model: {
                      value: item.check,
                      callback: function ($$v) {
                        _vm.$set(item, "check", $$v)
                      },
                      expression: "item.check",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }