import { validatenull } from './validate'
import request from '@/router/axios'
import * as CryptoJS from 'crypto-js'
import { Loading } from 'element-ui'
let loadingInstance = null
function loadingShowOrHide(show) {
  if (show) {
    loadingInstance = Loading.service({ fullscreen: true })
    // utils.showLoading()
  } else {
    loadingInstance && loadingInstance.close()
    // utils.hideLoading()
  }
}
// 表单序列化
export const serialize = data => {
  const list = []
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&')
}
export const getObjType = obj => {
  var toString = Object.prototype.toString
  var map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  if (obj instanceof Element) {
    return 'element'
  }
  return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
  var type = getObjType(data)
  var obj
  if (type === 'array') {
    obj = []
  } else if (type === 'object') {
    obj = {}
  } else {
    // 不再具有下一层次
    return data
  }
  if (type === 'array') {
    for (var i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i]))
    }
  } else if (type === 'object') {
    for (var key in data) {
      obj[key] = deepClone(data[key])
    }
  }
  return obj
}

/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
  delete obj1.close
  var o1 = obj1 instanceof Object
  var o2 = obj2 instanceof Object
  if (!o1 || !o2) {
    /*  判断不是对象  */
    return obj1 === obj2
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false
    // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object
    var t2 = obj2[attr] instanceof Object
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr])
    } else if (obj1[attr] !== obj2[attr]) {
      return false
    }
  }
  return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = status => {
  if (status) {
    document.body.className = document.body.className + ' grayMode'
  } else {
    document.body.className = document.body.className.replace(' grayMode', '')
  }
}
/**
 * 设置主题
 */
export const setTheme = name => {
  document.body.className = name
}

/**
 *加密处理
 */
export const encryption = params => {
  let { key } = params
  const { param } = params
  const result = JSON.parse(JSON.stringify(params.data))
  if (params.type === 'Base64') {
    param.forEach(ele => {
      result[ele] = btoa(result[ele])
    })
  } else {
    param.forEach(ele => {
      var data = result[ele]
      key = CryptoJS.enc.Latin1.parse(key)
      var iv = key
      // 加密
      var encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.NoPadding
      })
      result[ele] = encrypted.toString()
    })
  }
  return result
}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen()
  } else {
    reqFullScreen()
  }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = callback => {
  function listen() {
    callback()
  }

  document.addEventListener('fullscreenchange', function() {
    listen()
  })
  document.addEventListener('mozfullscreenchange', function() {
    listen()
  })
  document.addEventListener('webkitfullscreenchange', function() {
    listen()
  })
  document.addEventListener('msfullscreenchange', function() {
    listen()
  })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return (
    document.isFullScreen ||
    document.mozIsFullScreen ||
    document.webkitIsFullScreen
  )
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen()
  }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen()
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen()
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen()
  }
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i]
        } else {
          if (menu[i].children[j].children.length !== 0) {
            return findParent(menu[i].children[j].children, id)
          }
        }
      }
    }
  }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
  const link = document.createElement('link')
  link.type = 'text/css'
  link.rel = 'stylesheet'
  link.href = url
  const head = document.getElementsByTagName('head')[0]
  head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
  let result = true
  Object.keys(a).forEach(ele => {
    const type = typeof a[ele]
    if (type === 'string' && a[ele] !== b[ele]) result = false
    else if (
      type === 'object' &&
      JSON.stringify(a[ele]) !== JSON.stringify(b[ele])
    )
      result = false
  })
  return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = ''
  if (validatenull(dic)) return value
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  ) {
    let index = 0
    index = findArray(dic, value)
    if (index !== -1) {
      result = dic[index].label
    } else {
      result = value
    }
  } else if (value instanceof Array) {
    result = []
    let index = 0
    value.forEach(ele => {
      index = findArray(dic, ele)
      if (index !== -1) {
        result.push(dic[index].label)
      } else {
        result.push(value)
      }
    })
    result = result.toString()
  }
  return result
}

/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i
    }
  }
  return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4)
  if (date) random = random + Date.now()
  return random
}
/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
  // Fixes dual-screen position                            Most browsers       Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : screen.left
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : screen.top

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' +
      w +
      ', height=' +
      h +
      ', top=' +
      top +
      ', left=' +
      left
  )

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus()
  }
}

/**
 *  <img> <a> src 处理
 * @returns {PromiseLike<T | never> | Promise<T | never>}
 */
export function handleImg(url, id) {
  return validatenull(url)
    ? null
    : request({
      url: url,
      method: 'get',
      responseType: 'blob'
    }).then(response => {
      // 处理返回的文件流
      const blob = response.data
      const img = document.getElementById(id)
      img.src = URL.createObjectURL(blob)
      window.setTimeout(function() {
        window.URL.revokeObjectURL(blob)
      }, 0)
    }).catch(error => {})
}

/**
 *
 * @param url 目标下载接口
 * @param query 查询参数
 * @param fileName 文件名称
 * @returns {*}
 */
export function downBlobFile(url, query, fileName, method = 'get') {
  loadingShowOrHide(true)
  switch (method) {
    case 'get':
      return request({
        url: url,
        method: 'get',
        responseType: 'blob',
        params: query
      }).then(response => {
        loadingShowOrHide(false)
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
          this.$message.error(this.$strings.file_down_empty)
          return
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.setTimeout(function() {
          window.URL.revokeObjectURL(blob)
          document.body.removeChild(link)
        }, 0)
      }).catch(error => { loadingShowOrHide(false) })
    case 'post':
      return request({
        url: url,
        method: 'post',
        responseType: 'blob',
        data: query
      }).then(response => {
        loadingShowOrHide(false)
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
          this.$message.error(this.$strings.file_down_empty)
          return
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.setTimeout(function() {
          window.URL.revokeObjectURL(blob)
          document.body.removeChild(link)
        }, 0)
      }).catch(error => { loadingShowOrHide(false) })
    default:
      return request({
        url: url,
        method: 'get',
        responseType: 'blob',
        params: query
      }).then(response => {
        loadingShowOrHide(false)
        // 处理返回的文件流
        const blob = response.data
        if (blob && blob.size === 0) {
          this.$message.error(this.$strings.file_down_empty)
          return
        }
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        window.setTimeout(function() {
          window.URL.revokeObjectURL(blob)
          document.body.removeChild(link)
        }, 0)
      }).catch(error => { loadingShowOrHide(false) })
  }
  
}
export function downFileByUrl(url, fileName) {
 
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  a.click()
}
export function rewriteElTooltip(el) {
  
  el.methods = {
    ...el.methods,
    // 重写el-tooltip的show方法
    show() {
      // 如果设置了overflow，则判断文字是否溢出，若未溢出则不显示tooltip
      const width = this.$attrs['over-width'] ? this.$attrs['over-width'] : 170
      if (this.$el.offsetWidth <= width && this.$el.tagName !== 'I') return
      // el-tooltip自带的，保留
      this.setExpectedState(true)
      this.handleShowPopper()
    }
    // hide() {
    //   this.setExpectedState(false)
    //   this.debounceClose()
    //   setTimeout(() => {
    //     const list = document.getElementsByClassName("el-tooltip__popper")
    //     for (let index = 0; index < list.length; index++) {
    //       const element = list[index]
    //       element.remove()
    //     }  
    //   }, 50)
    // }
  }
}

export function resetOriUrl(url) {
  if (url.includes('http://') && window.location.origin.includes("https://")) {
    url = "https://" + url.split("http://")[1]
  }
  window.open(url)  
}

export function rewriteElMs(el, Vue) {
  Vue.prototype.$tms_confirm = (a, b, c) => {
    // c.callback = (action) => {
    //   setTimeout(() => {
    //     const list = document.getElementsByClassName("el-tooltip__popper")
    //     if (list.length > 0) {
    //       list[list.length - 1].style.display = "none"
    //     }   
    //   }, 50)
      
    // }
    return el.confirm(a, b, c)
  }
  
}
export function getQueryString(url, paraName) {
  const arrObj = url.split('?')
  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&')
    let arr
    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=')
      // eslint-disable-next-line eqeqeq
      if (arr !== null && arr[0] == paraName) {
        return arr[1]
      }
    }
    return ''
  } else {
    return ''
  }
}
//多语言校验插入函数
export const insertMsg = function() {
  let message = arguments[0]
  if (!message) {
    return ''
  }
  if (arguments.length > 1) {
    for (let i = 1; i < arguments.length; i++) {
      message = message.replace('{' + (i - 1) + '}', typeof arguments[i] === 'string' ? arguments[i].toLowerCase() : arguments[i])
    }
  }
  return message
}
export const getEncryEmail = function() {
  const email = arguments[0]
  if (!email) {
    return ''
  }
  const emailArr = email.split("@")
  let email_message = emailArr[0]
  const email_address = emailArr[1]
  email_message = email_message.slice(0, -4)
  return `${email_message}xxxx@${email_address}`
}
/**
 * 计算屏幕大小
 * @returns {number}
 */
export function calcScreen() {
  const width = document.body.clientWidth
  if (width >= 1200) {
    return 3 // 大屏幕
  } else if (width >= 992) {
    return 2 // 中屏幕
  } else if (width >= 768) {
    return 1 // 小屏幕
  } else {
    return 0 // 超小屏幕
  }
}
